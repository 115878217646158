/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react';
import {
  AccordionComponent,
  Button,
  Title,
  Text,
  ContentWrap,
  Icon,
} from './Accordion.styles.js';
import HouseCard from '../house-card/HouseCard.jsx';
import ChevronDownIcon from '../../assets/icons/ChevronDown.svg';
import { getHostname } from '../../utility/helpers';

import ContentLabel from '../content-label/ContentLabel';

function Accordion({
  homes,
  setSelectedHouse,
  showAllHomes,
  name,
  goalEndpoint,
}) {
  const [isActive, setIsActive] = useState(false);
  const [accordionHeight, setAccordionHeight] = useState(0);

  const content = useRef(null);
  const showMoreRef = useRef(null);

  async function toggleAccordion() {
    window.dataLayer = window.dataLayer || [];

    if (isActive) {
      window.scrollTo(0, showMoreRef.current.offsetTop - 500);

      window.dataLayer.push({
        event: 'HidePlots',
        developmentName: name,
        showPlots: false,
      });
    } else {
      window.dataLayer.push({
        event: 'ShowPlots',
        developmentName: name,
        showPlots: true,
      });

      await fetch(`${getHostname()}${goalEndpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }

    setIsActive(!isActive);
    setAccordionHeight(isActive ? '0px' : `${content.current.scrollHeight}px`);
    setSelectedHouse(!isActive);
  }

  useEffect(() => {
    if (showAllHomes) {
      setIsActive(true);
      setAccordionHeight(`${content.current.scrollHeight}px`);
    } else {
      setIsActive(false);
      setAccordionHeight(`0px`);
    }
  }, [showAllHomes]);

  return (
    <AccordionComponent isActive={isActive} ref={showMoreRef}>
      {isActive ? (
        <Title>
          {homes.length} <ContentLabel name="potentialHomesLabel" />
        </Title>
      ) : null}
      {!isActive ? (
        <Button
          isActive={isActive}
          showAllHomes={showAllHomes}
          onClick={toggleAccordion}
        >
          <Text>
            <ContentLabel name="showLabel" /> {homes.length}{' '}
            <ContentLabel name="homesAtThisDevelopmentLabel" />
          </Text>
          <Icon src={ChevronDownIcon} />
        </Button>
      ) : null}
      {homes ? (
        <ContentWrap ref={content} style={{ maxHeight: `${accordionHeight}` }}>
          {homes.map((home, i) => (
            <HouseCard
              key={i}
              name={home.name}
              location={home.location}
              bedrooms={home.bedrooms}
              priceLocalised={home.priceLocalised}
              price={home.price}
              image={home.image}
              url={home.url}
              distance={home.distance}
              lat={home.latitude}
              lng={home.longitude}
              status={home.status}
              specialOffer={home.specialOffer}
            />
          ))}
        </ContentWrap>
      ) : null}
      {isActive ? (
        <Button
          isActive={isActive}
          onClick={() => {
            toggleAccordion();
            setSelectedHouse(false);
          }}
        >
          <Text>
            <ContentLabel name="hideLabel" /> {homes.length}{' '}
            <ContentLabel name="homesAtThisDevelopmentLabel" />
          </Text>
          <Icon src={ChevronDownIcon} />
        </Button>
      ) : null}
    </AccordionComponent>
  );
}
export default Accordion;
